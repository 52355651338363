import React,{Component} from 'react'
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button } from "antd";
//import Layout from "../components/layout";
import axios from "axios";
require("./header.css");
require("../css/responsive.scss");
require("../css/business.css");

class Login extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
        visible: false,
        loading: false,
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPassword:"none"
        }
       // this.toggleMenu = this.toggleMenu.bind(this);
        // this.saveData = this.saveData.bind(this);
    }
     // anil code //
// anil code //

 

showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk()
  {
    var _this = this;
      var bodyFormData = new FormData();
  
     
      var email = document.getElementById("email").value;
      var password=document.getElementById("password").value
   
     
     
      
      var stringify;
      
  
      
       if (email == ""  ) {
        this.validateEmail();
      } 
      else if(password == "" )
      {
        this.validatePassword();
      }
      else {
     bodyFormData.set("email", email);
     bodyFormData.set("password",password);
     
     
       
    
        //console.log(_this);
        axios
          .post(
            "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/190/feedback",
            bodyFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(response => {
          //  console.log(response.status);
            document.getElementById("form").reset();
            if (response.status == 200 || response.status == 201) {
              _this.setState({
               
                loading: false
              });
            }
          })
          .catch(err => {
          //  console.log(err);
          });
      }
  }  

  handleCancel = e => {
   // console.log(e);
    this.setState({
      visible: false,
    });
  };

  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "") {
      this.setState({ displayEmail: "block" });
    } else {
      this.setState({ displayEmail: "none" });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
      }
    }
}



  validatePassword()
  {

    var password=document.getElementById("password").value;
    if(password == "")
    {
      this.setState({displayPassword:"block"});
    }
    else
    {
      this.setState({displayPassword:"none"});
    }
  }



//-------------anil code end--------//
 
 render()
 {
     return(
        <div>
               <button   onClick={ ()=>this.showModal()} className="loginbutton">
                        Login
                        </button> 
        <Modal
         title="Login"
         visible={this.state.visible}
         onOk={this.handleOk}
         onCancel={this.handleCancel}
         maskClosable={false}
        footer={[
          <Button key="back" onClick={() => this.handleCancel()}>
            Cancel
          </Button>,
          <Button
            className="BusinessFormBtn"
            key="submit"
            type="primary"
             loading={this.state.loading}
            onClick={() => this.handleOk()}
          >
            Submit
          </Button>
        ]}
        >
        
         <form action="javascript:void(0)" id="form">
            <div className="row form-group">
             
             
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
                <label htmlFor="email" className="NC-formlabel">
                  Email ID
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="email"
                  id="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  required
                  onInput={() => this.validateEmail()}
                />
                <p
                  style={{
                    display: this.state.displayEmail,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                   This field is required
                </p>
                 <p
                  style={{
                    display: this.state.displayEmailInvalid,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                *  The Entered Email id is not valid
                </p> 
           
             </div>
            
             <div className="col-md-12 col-xs-12 col-sm-12 padTop15">
             <label htmlFor="password" className="NC-formlabel">
               Password
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="password"
                  id="password"
                  required
                  onInput={() => this.validatePassword()}
                />
                  <p
                  style={{
                    display: this.state.displayPassword,
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
             
             </div>
           
          
           
            </div>
            
          </form>
          <div >
          
          {/* <div class="col-sm-12">

          <div class="col-sm-6">
          Not Registered? 
          </div>

          <div class="col-sm-6">
                    
          <Link to="/registration/">
         
         <p> Sign up</p>
        
          </Link>  
          </div>
          
          </div>
          */}
          
        

          </div>
         
        </Modal>
        </div>
        
     )
 }

}
export default Login