import React from "react";
//import { Link, navigate } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
//import Layout from "../components/layout";
import axios from "axios";
import SweetAlert from "sweetalert-react";
// import Dotimg from "../images/Rectangle.png";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";
import Login from "./../components/login"

import "antd/dist/antd.css";
import { Link, navigate } from "gatsby";
import { Spin } from "antd";

require("sweetalert/dist/sweetalert.css");
require("../css/payment.css");


var len;

class Profile extends React.Component {
  constructor(props) {
    super(props);
  //  console.log(this.props);
    this.state = {
      showAlert: false,
      displayName: "none",
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPassword:"none",
      displayconfirmpassword:"none",
      displayPhone: "none",
      displayPhoneInvalid: "none",
      displayPincode: "none",
      displayPincodeInvalid: "none",
      displayHouse: "none",
      displayArea: "none",
      displayLandmark: "none",
      displayTown: "none",
      displayState: "none",
      displayEmail: "none",
      displayOrg: "none",
      displayQty: "none",
      displayInvalidPassword:"none",
      pwsnotmatch:"none",
       //data:false,
       displayalreadyexit:false,
       alreadyhaveemail:"none",
       emailpws:false,
       invalidPws:"none",
       redirect:"none",
       pro_fullName:"",
       pro_phone:"",
       pro_pincode:"",
       pro_building:"",
       pro_town:"",
       pro_state:"",
       pro_landmark:"",
       pro_area:"",
       pro_email:"",
       pro_alert:false,
       isRegistered:false,
       Spin:false

      
      
    };
    // this.handleDeliveryChange = this.handleDeliveryChange.bind(this);
    // this.handleAddressChange = this.handleAddressChange.bind(this);
  }
 


  componentDidMount() {
    this.setState({
      spin:true
    },
    () => {
      setTimeout(() => {
        this.setState({ spin: false });
      }, 1000);
    }
    )

     var email="";
    var registered=window.sessionStorage.getItem("registeredemail");
    var login=window.sessionStorage.getItem("email");
    if(window.sessionStorage.getItem("registeredemail")==null || (registered && login) )
    {
      email=window.sessionStorage.getItem("email");
    }
    else
    {
      
      email=window.sessionStorage.getItem("registeredemail");
      this.setState({
        pro_email:email,
        isRegistered:true

      })

    }
   
    
    // var info = this.props.data.allMysqlLeads.edges;

    // var testdata=info.filter(ele=>{
    //   if( ele.node.email==email)
    //   {
    //      this.setState({
    //        pro_fullName:ele.node.full_name,
    //        pro_phone:ele.node.phone,
    //        pro_email:ele.node.email,
    //        pro_pincode:ele.node.pincode,
    //        pro_building:ele.node.building,
    //        pro_landmark:ele.node.landmark,
    //        pro_area:ele.node.area,
    //        pro_state:ele.node.state,
    //        pro_town:ele.node.town


    //      })
    //   }

    // })
   // console.log(this.state.pro_fullName);
   var dataobject={
    email:email

  }
   axios.post("https://dashboard.nativecircle.in/wp-json/api/login",dataobject)
    .then(response=>{
      console.log(response.data.message)
      if(response.data.status==200){
          var payDetails=response.data.message[0]
          var phone,pincode
          payDetails.phone==0 ? phone="" : phone=payDetails.phone
          payDetails.pincode==0 ? pincode="" :pincode=payDetails.pincode
          this.setState({
            pro_fullName:payDetails.full_name,
            pro_phone:phone,
            pro_email:payDetails.email,
            pro_pincode:pincode,
            pro_building:payDetails.building,
            pro_landmark:payDetails.landmark,
            pro_area:payDetails.area,
            pro_state:payDetails.state,
            pro_town:payDetails.town
        
        
                 })
         
      }
      
    })
    .catch(err => {
      console.log(err);
    });

   }


  
  proceedtoRegister() {
   // var name = document.getElementById("name").value;
    //var number = document.getElementById("number").value;
    var email = document.getElementById("email").value;
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneLength = document.getElementById("number").value.length;
    var pincodeLength = document.getElementById("pincode").value.length;
   // var password=document.getElementById("password").value;
   // var confirmpassword=document.getElementById("confirmpassword").value;
    // var pincode = document.getElementById("pincode").value;
    // var house = document.getElementById("building").value;
    // var area = document.getElementById("area").value;
    // var landmark = document.getElementById("landmark").value;
    // var town = document.getElementById("town").value;
    // var state = document.getElementById("state").value;
//console.log(name)
    if (this.state.pro_fullName == "") {
      // console.log("name is empty");
      this.validateName();
    } else if (this.state.pro_phone == "" || phoneLength<10) {
      this.validatePhone();
    }  else if (this.state.pro_email == "" || !pattern.test(email)) {
      this.validateEmail();
    } else if (this.state.pro_pincode == "" || pincodeLength < 6) {
      this.validatePincode();
    } else if (this.state.pro_house == "") {
      this.validateHouse();
    } else if (this.state.pro_area == "") {
      this.validateArea();
    } else if (this.state.pro_landmark == "") {
      this.validateLandmark();
    } else if (this.state.pro_town == "") {
      this.validateTown();
    } else if (this.state.pro_state == "") {
      this.validateState();
    } else {
      var _this = this;
    
        
         document.getElementById("rzp-button1").disabled=true
          var bodyFormData = new FormData();
          
         
         
          bodyFormData.set("fullName",this.state.pro_fullName);
          bodyFormData.set("phone",this.state.pro_phone);
          bodyFormData.set("email", this.state.pro_email);
          //bodyFormData.set("password", document.getElementById("password").value);
          
          bodyFormData.set("pincode",this.state.pro_pincode) ;
          bodyFormData.set("building",this.state.pro_building);
          bodyFormData.set("area", this.state.pro_area);
          bodyFormData.set("landmark",this.state.pro_landmark);
          bodyFormData.set("town",this.state.pro_town);
          bodyFormData.set("state",this.state.pro_state);
          bodyFormData.set("type","profile");
          bodyFormData.set("formID", 1223);
          

          axios
            .post(
              "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/1223/feedback",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }

              }
            )
            .then(response => {
           console.log("the respose is",response)
              document.getElementById("form").reset();
              if (response.status == 200 || response.status == 201) {
                //document.getElementById("rzp-button1").disabled = true;
                if(this.state.isRegistered==false)
                {
                  _this.setState({
                    pro_alert:true
                  })
                }
                else
                {
                  _this.setState({
                    showAlert: true
                  });
                }
               
                
              }

              
            })
            .catch(err => {
            //  console.log(err);
            });
          
          }
         
  }

  // validateName() {
  //   var name = document.getElementById("name").value;
  //   if (name == "") {
  //     this.setState({ displayName: "block" });
  //   } else {
  //     this.setState({ displayName: "none" });
  //   }
  // }
  validateName() {
    var name = document.getElementById("name").value.replace(/[^a-zA-Z\s]/g, "");
    console.log("name", name)
    if (name == "" || name == undefined) {
      this.setState({ displayName: "block", pro_fullName: "" });
    } else {
      this.setState({ displayName: "none", pro_fullName: name });
    }
  }
  
  validatePhone() {
   
    var phone = document.getElementById("number").value;
    var phonePattern =  /^\d+$/.test(phone);
    // var phoneValue = document.getElementById("number").value.replace(/[^\d]/,'');

    if(phonePattern == true){
      var phoneLength = document.getElementById("number").value.length;
      if (phone == "" || phone == undefined) {
        this.setState({ displayPhone: "block", pro_phone: "" });
      } else {
        this.setState({ displayPhone: "none", pro_phone: phone });
        // console.log("phonePattern.test(phone)", phonePattern.test(phone));
        if (phoneLength < 10) {
          this.setState({ displayPhoneInvalid: "block" });
        } else {
          this.setState({ displayPhoneInvalid: "none" });
        }
      }
    }
    else{
      this.setState({ displayPhone: "block", pro_phone: "" });
    }
    
  }
 
 
  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "" || email == undefined) {
      this.setState({ displayEmail: "block", pro_email: "" });
    } else {
      this.setState({ displayEmail: "none", pro_email: email });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
      }
    }
  }


  validatePincode() {
    //  var pincode = e.target.value;
    var pincode = document.getElementById("pincode").value.replace(/[^\d]/,'');
    var pincodeLength = document.getElementById("pincode").value.length;
    console.log("pincode", pincode)
    if (pincode == "") {
      this.setState({ displayPincode: "block", pro_pincode: "" });
    } else {
      this.setState({ displayPincode: "none", pro_pincode: pincode });
      if (pincodeLength < 6) {
        this.setState({ displayPincodeInvalid: "block" });
      } else {
        this.setState({ displayPincodeInvalid: "none" });
      }
    }
  }

  validateHouse() {
    var house = document.getElementById("building").value;
    if (house == "" || house == undefined) {
      this.setState({ displayHouse: "block", pro_building: "" });
    } else {
      this.setState({ displayHouse: "none", pro_building: house });
    }
  }

  validateArea() {
    var area = document.getElementById("area").value;
    if (area == "" || area == undefined) {
      this.setState({ displayArea: "block", pro_area: "" });
    } else {
      this.setState({ displayArea: "none", pro_area: area });
    }
  }

  validateLandmark() {
    var landmark = document.getElementById("landmark").value;
    if (landmark == "" || landmark == undefined) {
      this.setState({ displayLandmark: "block", pro_landmark: "" });
    } else {
      this.setState({ displayLandmark: "none", pro_landmark: landmark });
    }
  }

  validateTown() {
    var town = document.getElementById("town").value;
    if (town == "" || town == undefined) {
      this.setState({ displayTown: "block", pro_town: "" });
    } else {
      this.setState({ displayTown: "none", pro_town: town });
    }
  }

  validateState() {
    var state = document.getElementById("state").value;
    if (state == "" || state == undefined) {
      this.setState({ displayState: "block", pro_state: "" });
    } else {
      this.setState({ displayState: "none", pro_state: state });
    }
  }





  
  
  

  render() {
    var today = new Date();
    return (
      <div class="bckclr">
         <Spin  tip="Loading..."
          spinning={this.state.spin}
          wrapperClassName="spinClass">



         <SweetAlert
          show={this.state.showAlert}
          title="Thank you for registering !"
          text="Please login to continue."
          onConfirm={() =>{this.setState({ showAlert: false, visible: false })
          navigate("/login");
        
        } }
        />
        <SweetAlert
          show={this.state.pro_alert}
          title="Your profile is updated"
          onConfirm={() =>{this.setState({ pro_alert:false, visible: false })
         // navigate("/");
            
        
        } }
        />
        




       
        <HeaderLight pathname="/profile"/>
       
        <div id="circle-rectang" className="container1 NC-vision">
         {/* ------------------------------------anil code---------------- */}
         <div class="Registration_panel">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xs-12 pad0">
              <form
                action="javascript:void(0)"
                // onSubmit={this.postForm.bind(this)}
                id="form"
              >
                <div className="row form-group">
                  

                  <div className="col-md-12 col-xs-12 col-sm-12 padTop15 formHeading registrationstyling">
                   PROFILE
                  </div>

                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="name" className="NC-formlabel">
                      Full name *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="name"
                      value={this.state.pro_fullName}
                     
                      //required
                      onChange={() => this.validateName()}
                      // onChange={()=>this.setState({
                      //   pro_fullName:document.getElementById("name").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayName,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                    

                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="number" className="NC-formlabel">
                      Mobile number *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="number"
                      value={this.state.pro_phone}
                     maxlength="10"
                     size="10"
                     pattern="\d{10}"
                     
                      //  required
                      onChange={() => this.validatePhone()}
                      // onChange={()=>this.setState({
                      //   pro_phone:document.getElementById("number").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayPhone,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                    <p
                      style={{
                        display: this.state.displayPhoneInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      Please put 10 digit mobile number
                    </p>
                  </div>


                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="number" className="NC-formlabel">
                      Email *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="email"
                      id="email"
                      value={this.state.pro_email}
                  
                    />
                    {/* <p
                      style={{
                        display: this.state.displayEmail,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p> */}
                    {/* <p
                      style={{
                        display: this.state.displayEmailInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      The Entered Email id is not valid
                    </p> */}


                   
                    
                  </div>




                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="pincode" className="NC-formlabel">
                      Pincode *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      maxlength="6"
                      size="6"
                      pattern='\d{6}'
                      id="pincode"
                      value={this.state.pro_pincode}
                      // required
                      onChange={() => this.validatePincode()}
                      // onChange={()=>this.setState({
                      //   pro_pincode:document.getElementById("pincode").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayPincode,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>

                    <p
                      style={{
                        display: this.state.displayPincodeInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      Please enter 6 digit pincode
                    </p>
                  </div>

                  
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="building" className="NC-formlabel">
                      Flat , House no., Building *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="building"
                      value={this.state.pro_building}
                      //  required
                      onChange={() => this.validateHouse()}
                      // onChange={()=>this.setState({
                      //   pro_building:document.getElementById("building").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayHouse,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="area" className="NC-formlabel">
                      Area, Colony, Street *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="area"
                      value={this.state.pro_area}
                      //  required
                      onChange={() => this.validateArea()}
                      // onChange={()=>this.setState({
                      //   pro_area:document.getElementById("area").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayArea,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="landmark" className="NC-formlabel">
                      Landmark *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="landmark"
                      value={this.state.pro_landmark}
                      //  required
                      onChange={() => this.validateLandmark()}
                      // onChange={()=>this.setState({
                      //   pro_landmark:document.getElementById("landmark").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayLandmark,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="town" className="NC-formlabel">
                      Town, City *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="town"
                      value={this.state.pro_town}
                      //  required
                      onChange={() => this.validateTown()}
                      // onChange={()=>this.setState({
                      //   pro_town:document.getElementById("town").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayTown,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="state" className="NC-formlabel">
                      State *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="state"
                      value={this.state.pro_state}
                      //  required
                      onChange={() => this.validateState()}
                      // onChange={()=>this.setState({
                      //   pro_state:document.getElementById("state").value
                      // })}
                    />
                    <p
                      style={{
                        display: this.state.displayState,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  
                 

                  <div className="col-md-12 col-xs-12 col-sm-12 NC-formsubmit">
                    <button
                      className="proceedBtn"
                      id="rzp-button1"
                      htmlType="submit"
                      onClick={() => this.proceedtoRegister()}
                      href=""
                     
                    >
                      Save profile{" "}
                      
                    </button>
                  
                  </div>
                  {/* <p class="loginbtnn">have an account?<a href="/login/">Log in.</a></p> */}
                </div>
              </form>
            </div>
          </div>
          </div>
        </div>
        <div className="NC-paddingTop-50">
          <Footer />
        </div>
        </Spin>
      </div>
    );
  }
}
export default Profile;

// export const pageQuery = graphql`

// query {
//   allMysqlwp_registration {
//     edges {
//       node {
//         email
       
//       }
//     }
//   }
// }

// export const pageQuery = graphql`
// query Registrationquery {
//   allMysqlLeads {
//     edges {
//       node {
//         full_name
//         phone
//         email
//         pincode
//         building
//         area
//         landmark
//         town
//         state
        

        
//       }
//     }
//   }
// }
// `;


